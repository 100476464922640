import React from "react"
import { PrismicRichText, getLink } from "./../../components/shared"
import { Link } from "gatsby"

const JoinAccuIndex = ({
  demo_account_link = {},
  demo_account_label = [],
  
  join_heading = {},
  live_account_link = {},
  live_account_label = [],
  text_below_demo_and_live_buttons = {},
}) => {

  // const getLink = (str) => {
  //   if(!str)
  //     return '/';
  //   const tmp = str.split('/');
  //   return tmp[ tmp.length - 1 ]
  // }

  // const [demoAccountIcon, setDemoAccountIcon] = useState(demo_account_icon.url)
  // const [liveAccountIcon, setLiveAccountIcon] = useState(live_account_icon.url)
  return (
    <>
      <section className="pad50">
        <div className="container">
          <div className="row text-center">
            <div className="col-lg-8 joinContent">
              <h3>{join_heading.text}</h3>
              <div className="row">
                <div className="col-lg-6 tile">
                  <div
                    className="text-center tileBox"
                    // onMouseEnter={e =>
                    //   setDemoAccountIcon(demo_account_icon_hover.url)
                    // }
                    // onMouseLeave={e =>
                    //   setDemoAccountIcon(demo_account_icon.url)
                    // }
                  >
                    {/* <a
                      href={demo_account_link.url}
                      className="btn btn-block"
                      target="_blank"  rel="noreferrer"
                    >
                        <button type="button" class="btn btn-small btn-shaded-blue">
                          {demo_account_label.text}
                        </button>
                    </a> */}
                    <Link className="btn btn-block" to={getLink(demo_account_link.url)}>
                      <button type="button" class="btn btn-small btn-shaded-blue">
                        {demo_account_label.text}
                      </button>
                    </Link>

                  </div>
                </div>
                <div className="col-lg-6 tile">
                  <div
                    className="text-center tileBox"
                    // onMouseEnter={e =>
                    //   setLiveAccountIcon(live_account_icon_hover.url)
                    // }
                    // onMouseLeave={e =>
                    //   setLiveAccountIcon(live_account_icon.url)
                    // }
                  >
                    <a
                      href={live_account_link.url}
                      className="btn btn-block"
                      target="_blank"  rel="noreferrer"
                    >
                      <button type="button" class="btn btn-small btn-shaded-blue">
                        {live_account_label.text}
                      </button>
                    </a>
                  </div>
                </div>
              </div>
              <div className="description">
                <PrismicRichText data={text_below_demo_and_live_buttons.raw} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default JoinAccuIndex
